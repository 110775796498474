import * as React from 'react';
import { PageLayout } from 'src/components/Layout/PageLayout';
import { Container } from 'src/components/Container/Container';
import { Frown } from 'react-feather';
import { Link } from 'gatsby';

const NotFoundPage: React.FC = () => (
  <PageLayout pageTitle='Not found'>
    <Container>
      {/* <h1>Page not found</h1>
      <h3>
        The content you are looking for does not exist... the sadness. <Frown />
      </h3> */}
      <Link to='outreachworkshopBETA'>Get notified when LinkScore BETA is released!</Link>
    </Container>
  </PageLayout>
);

export default NotFoundPage;
